
  import SecondaryNavigation from '@/components/SecondaryNavigation.vue';

  export default {
    data: function() {
      return {
        menuEntries: [
          {
            title: "Nexus HQ",
            image: require("@/assets/community-menu/nexus-hq.png"),
            href: "/nexus-hq",
          },
          {
            title: "News Network",
            image: require("@/assets/community-menu/news-network.png"),
            links: [
              { title: "Top Stories", href: "/community/news-network" },
              {
                title: "Game News",
                href: {name: 'category', params: {key: 'game-news'}}
              },
              {
                title: "General News",
                href: {name: 'category', params: {key: 'general-news'}}
              },
              {
                title: "Challenges",
                href: {name: 'category', params: {key: 'challenges'}}
              },
              {
                title: "Events",
                href: {name: 'category', params: {key: 'events'}}
              },
              {
                title: "My LEGO Network",
                href: {name: 'category', params: {key: 'my-lego-network'}}
              },
              { title: "Newsletter Archives", href: "/community/news-network/newsletter-archive" },
            ]
          },
          {
            title: "Message Board",
            image: require("@/assets/community-menu/message-board.png"),
            href: "/community/message-board",
          },
          {
            title: "Championships",
            image: require("@/assets/community-menu/championships.png"),
            links: [
              { title: "Standings", href: "/community/championship" },
              { title: "Information", href:"/community/news-network/s/355487" },
            ]
          },
          {
            title: "Creation Lab",
            image: require("@/assets/community-menu/creation-lab.png"),
            links: [
              { title: "Brickkeeper's Lab", href: "/community/creation-lab" },
              { title: "All Creations", href:"/community/creation-lab/creation-list" },
              { title: "All Challenges", href:"/community/creation-lab/challenge-list" }
            ]
          },
          {
            title: "Polls",
            image: require("@/assets/community-menu/polls.png"),
            href: {name: 'category', params: {key: 'polls'}},
          },
          {
            title: "Hall of Fame",
            image: require("@/assets/community-menu/hall-of-fame.png"),
            href: "/community/property-of-the-week/hall-of-fame",
            //href: "/community/ingame-events/341489"
          },
          {
            title: "Release notes",
            image: require("@/assets/community-menu/release-notes.png"),
            href: "/community/release-notes",
            /*links: [
              { title: "September 2011", href: "/community/content/353041" },
              { title: "August 2011", href: "/community/content/343112" },
              { title: "June 2011", href: "/community/content/318461" },
              { title: "May 2011", href: "/community/content/311431" },
              { title: "March 2011", href: "/community/content/300284" },
              { title: "February 2011", href: "/community/content/281614" },
              { title: "January 2011", href: "/community/content/272833" },
              { title: "December 2010", href: "/community/content/248213" },
              { title: "October 2010", href: "/community/content/226072" },
              { title: "September 2010", href: "/community/content/221651" },
              { title: "August 2010", href: "/community/content/221649" },
              { title: "July 2010", href: "/community/content/221647" },
              { title: "Older Release Notes", href: "/community/content/221646" },
            ]*/
          }
        ]
      }
    },
    components: {
      SecondaryNavigation,
    }
  }
