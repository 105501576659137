<template>
  <div id="secondaryNavigation">
    <div class="column-top"></div>
    <div class="column-content">
      <ul id="SectionMenu">
        <section-menu-entry v-for="entry in menuEntries" v-bind="entry" v-bind:key="entry.title"></section-menu-entry>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
  import SectionMenuEntry from '@/components/SectionMenuEntry.vue';

  export default {
    props: ["menuEntries"],
    components: {
      SectionMenuEntry,
    }
  }
</script>
