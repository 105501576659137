
import { Link } from "@/options";
import { defineComponent, PropType } from "vue";
import { RouteLocationRaw } from "vue-router";

export default defineComponent({
  data() {
    return {
      selected: false,
    };
  },
  computed: {
    mainClass: function () {
      return { selected: this.selected };
    },
    mainStyle: function () {
      var sty: Record<string, any> = {};
      if (this.selected && this.links) {
        const len = this.links.length * 40;
        sty['max-height'] = `${len}px`;
      } else {
        sty['max-height'] = '0px';
      }
      return sty;
    }
  },
  methods: {
    toggleSelected: function () {
      this.selected = !this.selected;
    }
  },
  props: {
    title: String,
    image: String,
    links: Array as PropType<Array<Link>>,
    href: Object as PropType<RouteLocationRaw>,
  }
});
