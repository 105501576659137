<template>
  <li v-if="links" :class="mainClass">
    <span @click="toggleSelected" class="buttonBig buttonExpandable">
      <router-link :to="links[0].href">
        <img style="width: 25px; height: 25px;" :src="image" :alt="title" :title="title">{{ title }}
      </router-link>
    </span>
    <ul :style="mainStyle">
      <li v-for="link in links" :key="link.title"><span class="">
          <router-link :to="link.href" class="linkArrow">{{ link.title }}</router-link>
        </span></li>
    </ul>
  </li>
  <li v-else-if="href">
    <router-link :to="href" class="buttonBig">
      <span><img style="width: 25px; height: 25px;" :src="image" :alt="title" :title="title">{{ title }}</span>
    </router-link>
  </li>
</template>

<script lang="ts">
import { Link } from "@/options";
import { defineComponent, PropType } from "vue";
import { RouteLocationRaw } from "vue-router";

export default defineComponent({
  data() {
    return {
      selected: false,
    };
  },
  computed: {
    mainClass: function () {
      return { selected: this.selected };
    },
    mainStyle: function () {
      var sty: Record<string, any> = {};
      if (this.selected && this.links) {
        const len = this.links.length * 40;
        sty['max-height'] = `${len}px`;
      } else {
        sty['max-height'] = '0px';
      }
      return sty;
    }
  },
  methods: {
    toggleSelected: function () {
      this.selected = !this.selected;
    }
  },
  props: {
    title: String,
    image: String,
    links: Array as PropType<Array<Link>>,
    href: Object as PropType<RouteLocationRaw>,
  }
});
</script>
